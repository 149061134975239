import React from 'react'

import dynamic from 'next/dynamic'

import { useTranslation } from '@/lib/i18n'

import { LICENCE_CONTRACT_FORM_ID } from './licence-contract-form-id'

import { FormLoading } from '../form-components/form-loading'
import { FormDrawer } from '../form-drawer'
import { useContractFormButtonTitle } from '../licence-contract/use-contract-form-button-title'

import { LicenceContractStatus } from '../../../../lib/generated/graphql'

type LicenceContractFormDialogButtonProps = {
    licenceContractId?: string
    licenceContractStatus?: LicenceContractStatus
    preselectedLicenseeId?: string
}

const ActiveLicenceContractForm = dynamic(() => import('./active-licence-contract-form').then((mod) => mod.ActiveLicenceContractForm), {
    loading: () => <FormLoading />,
})
const LicenceContractForm = dynamic(() => import('./licence-contract-form').then((mod) => mod.LicenceContractForm), {
    loading: () => <FormLoading />,
})

export const LicenceContractFormButton: React.FC<LicenceContractFormDialogButtonProps> = ({
    licenceContractId,
    licenceContractStatus,
    preselectedLicenseeId,
}) => {
    const { t } = useTranslation('common')

    let { buttonTitle, buttonType, dialogTitle } = useContractFormButtonTitle(t('contract.form.editContract'), licenceContractStatus)

    const isNewForm = !licenceContractId

    if (isNewForm) {
        buttonTitle = t('contract.form.newContract')
        buttonType = 'new'
        dialogTitle = t('contract.form.newContract')
    }

    return (
        <FormDrawer buttonTitle={buttonTitle} buttonType={buttonType} title={dialogTitle} formId={LICENCE_CONTRACT_FORM_ID}>
            {({ toggleOpenDrawer, setSubmitting }) =>
                licenceContractStatus === LicenceContractStatus.ACTIVE && licenceContractId ? (
                    <ActiveLicenceContractForm
                        formId={LICENCE_CONTRACT_FORM_ID}
                        licenceContractId={licenceContractId}
                        onSuccess={toggleOpenDrawer}
                        setSubmitting={setSubmitting}
                    />
                ) : (
                    <LicenceContractForm
                        formId={LICENCE_CONTRACT_FORM_ID}
                        licenceContractId={licenceContractId}
                        onSuccess={toggleOpenDrawer}
                        setSubmitting={setSubmitting}
                        preselectedLicenseeId={preselectedLicenseeId}
                    />
                )
            }
        </FormDrawer>
    )
}
